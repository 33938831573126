import React, { FC, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { useTheme } from 'styled-components'

import { Props } from './Trustpilot'
import { WidgetType } from './types'

declare global {
  interface Window {
    Trustpilot: any
  }
}

window.Trustpilot = window.Trustpilot || {}

const WIDGET_ATTRIBUTES: Record<
  WidgetType,
  {
    templateId: string
    styleHeight: string
    dataStars?: string
  }
> = {
  horizontal: {
    templateId: '5406e65db0d04a09e042d5fc',
    styleHeight: '28px',
  },
  carousel: {
    templateId: '53aa8912dec7e10d38f59f36',
    styleHeight: '140px',
    dataStars: '4,5',
  },
  'micro-star': {
    templateId: '5419b732fbfb950b10de65e5',
    styleHeight: '24px',
  },
  grid: {
    templateId: '539adbd6dec7e10e686debee',
    styleHeight: '500px',
    dataStars: '4,5',
  },
  mini: {
    templateId: '53aa8807dec7e10d38f59f32',
    styleHeight: '150px',
  },
}

export const TrustBox: FC<Pick<Props, 'widgetType' | 'themeStyle'>> = ({
  widgetType,
  themeStyle,
}) => {
  const theme = useTheme()

  const trustBoxRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      'Trustpilot' in window &&
      typeof window.Trustpilot.loadFromElement === 'function' &&
      !!trustBoxRef.current
    )
      window.Trustpilot.loadFromElement(trustBoxRef.current, true)
  }, [])

  const getWidgetAttribute = (
    attribute: keyof (typeof WIDGET_ATTRIBUTES)[WidgetType]
  ) => WIDGET_ATTRIBUTES[widgetType]?.[attribute]

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>

      <div
        ref={trustBoxRef}
        className="trustpilot-widget"
        data-locale={`${process.env.GATSBY_LANG}-${process.env.GATSBY_COUNTRY}`}
        data-template-id={getWidgetAttribute('templateId')}
        data-businessunit-id={
          process.env.GATSBY_COUNTRY === 'IT'
            ? '5bf7ed6d996c850001ba27c0'
            : '570e5b060000ff00058b7795'
        }
        data-font-family="Inter"
        data-text-color={
          themeStyle === 'dark'
            ? theme.tokens.text.paragraph.reversed
            : theme.tokens.text.paragraph.regular
        }
        data-style-height={getWidgetAttribute('styleHeight')}
        data-style-width="100%"
        data-theme={themeStyle}
        data-stars={getWidgetAttribute('dataStars')}
      >
        <a
          href="https://www.trustpilot.com/review/moneyfarm.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </>
  )
}
