import React, { FC } from 'react'
import styled from 'styled-components'

import { marginBreakpoints, hideBlockAt } from '../../../../utils/commonAttrs'
import { TrustBox } from './TrustBox'
import { BaseBlockProps } from '../../../../types/BaseBlockProps'
import { WidgetType } from './types'

const TrustBoxContainer = styled.div<BaseBlockProps>`
  ${({ hiddenAt }) => hideBlockAt({ hiddenAt })};
  ${({ marginBottom }) => marginBreakpoints({ marginBottom })};
`

export type Props = BaseBlockProps & {
  widgetType: WidgetType
  themeStyle: string
}

export const Trustpilot: FC<Props> = ({
  marginBottom,
  hiddenAt,
  widgetType,
  themeStyle,
}) => (
  <TrustBoxContainer marginBottom={marginBottom} hiddenAt={hiddenAt}>
    <TrustBox widgetType={widgetType} themeStyle={themeStyle} />
  </TrustBoxContainer>
)
